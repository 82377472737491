<template>
  <div class="grid grid-flow-row gap-4">
    <div class="flex justify-center min-height-auto">
      <h1
        class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
      >
        Ventas
      </h1>
    </div>

    <span>
      <svg
        class="w-5 h-5 inline-block text-gray-800"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-width="2"
          d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z"
        />
      </svg>
      Filtro por fecha:
    </span>
    <!--Fechas y flitro-->
    <div class="grid grid-cols-1 lg:grid-cols-5 gap-4">
      <div>
        <input
          type="date"
          v-model="startDate"
          @change="filterLavados"
          class="form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
        />
      </div>
      <div>
        <input
          type="date"
          v-model="endDate"
          @change="filterLavados"
          class="form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
        />
      </div>
      <div>
        <router-link :to="{ path: '/formlavado', query: { isAdmin: true } }">
          <button
            type="button"
            class="focus:outline-none text-white bg-[#3edfa9] hover:bg-[#ffe068] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 w-full"
          >
            <svg
              class="w-6 h-6 inline-block text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Agregar Venta
          </button></router-link
        >
      </div>
      <div>
        <button
          type="button"
          @click="fetchLavados"
          class="focus:outline-none text-white bg-[#3edfa9] hover:bg-[#ffe068] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 w-full"
        >
          <svg
            class="w-6 h-6 inline-block text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
            />
          </svg>
          Actualizar
        </button>
        
      </div>
      <div>
  <button
    type="button"
    @click="exportToExcel"
    class=" text-white bg-[#3edfa9] hover:bg-[#ffe068]  font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 w-full"
  ><svg  class="w-6 h-6 inline-block text-white" fill="#fff" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>export-line</title> <path d="M6,13.61h7.61V6H24v8.38h2V6a2,2,0,0,0-2-2H10.87L4,10.87V30a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2H6Zm0-1.92L11.69,6H12v6H6Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M28.32,16.35a1,1,0,0,0-1.41,1.41L30.16,21H18a1,1,0,0,0,0,2H30.19l-3.28,3.28a1,1,0,1,0,1.41,1.41L34,22Z" class="clr-i-outline clr-i-outline-path-2"></path> <rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect> </g></svg>
    Exportar a Excel
  </button>
</div>
    </div>
    <!--Fechas y flitro-->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div
        class="bg-green-100 text-green-800 text-md font-medium me-2 px-3.5 py-2 rounded"
      >
        <svg
          class="w-6 h-6 inline-block text-gray-800"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 3v4a1 1 0 0 1-1 1H5m8-2h3m-3 3h3m-4 3v6m4-3H8M19 4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 12v6h8v-6H8Z"
          />
        </svg>
        Registros: {{ filteredLavados.length }}
      </div>

      <div
        class="bg-green-100 text-green-800 text-md font-medium me-2 px-3.5 py-2 rounded"
      >
        <svg
          class="w-6 h-6 inline-block text-gray-800"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"
          />
        </svg>
        Facturados: ${{ calculateTotalImporte() }}
      </div>
      <div class="flex justify-start min-height-auto">
        <form>
          <select
            v-model="results"
            name="results"
            id="results"
            @change="updateResults"
            class="bg-gray-50 border border-[#3edfa9] text-gray-900 text-sm rounded-lg focus:ring-[#3edfa9] focus:border-[#3edfa9] block w-full p-2.5"
          >
            <option selected value="10">Mostrar resultados</option>
            <option value="20">Mostrando 20 resultados</option>
            <option value="30">Mostrando 30 resultados</option>
            <option value="50">Mostrando 50 resultados</option>
          </select>
        </form>
      </div>
    </div>
    <hr class="divide-y divide-gray-100" />

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        v-if="filteredLavados.length > 0"
        class="w-full text-sm text-left rtl:text-right text-gray-500"
      >
        <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
          <tr>
            <th scope="col" class="px-4 py-3">Fecha</th>
            <th scope="col" class="px-4 py-3">Descripción</th>
            <th scope="col" class="px-4 py-3">Servicio</th>
            <th scope="col" class="px-4 py-3">Metodo de Pago</th>
            <th scope="col" class="px-4 py-3">Importe</th>
            <th scope="col" class="px-4 py-3">Accion</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b"
            v-for="(lavado, index) in filteredLavados"
            v-show="(pag - 1) * results <= index && pag * results > index"
            :key="lavado.id"
          >
            <th
              scope="row"
              class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              {{ formatDate(lavado.fecha) }}
            </th>
            <td class="px-4 py-4">{{ lavado.descripcion }}</td>
            <td class="px-4 py-4">
              {{ lavado.servicio ? lavado.servicio.nombre : "Sin Servicio" }}
            </td>
            <td class="px-4 py-4">{{ lavado.metodoPago }}</td>
            <td class="px-4 py-4">${{ lavado.importe }}</td>
            <td
              class="grid lg:flex md:flex px-2 md:px-2 py-4 lg:text-left md:text-center"
            >
              <a
                href="#"
                @click="editLavado(lavado)"
                class="font-regular text-xs text-gray-800 hover:underline"
                ><svg
                  class="w-4 h-4 inline-block text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                  />
                </svg>
                Editar</a
              >
              <a
                href="#"
                @click="deleteModal(lavado.id)"
                class="font-medium text-xs text-red-500 hover:underline lg:ml-2"
                ><svg
                  class="w-4 h-4 inline-block text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#ef4444"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                  />
                </svg>
                Eliminar</a
              >

              <a
                href="#"
                @click="generatePdf(lavado.id)"
                class="font-medium text-xs text-gray-800 hover:underline lg:ml-2"
              >
                <svg
                  class="w-4 h-4 inline-block text-gray-800"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M5.625 15C5.625 14.5858 5.28921 14.25 4.875 14.25C4.46079 14.25 4.125 14.5858 4.125 15H5.625ZM4.875 16H4.125H4.875ZM19.275 15C19.275 14.5858 18.9392 14.25 18.525 14.25C18.1108 14.25 17.775 14.5858 17.775 15H19.275ZM11.1086 15.5387C10.8539 15.8653 10.9121 16.3366 11.2387 16.5914C11.5653 16.8461 12.0366 16.7879 12.2914 16.4613L11.1086 15.5387ZM16.1914 11.4613C16.4461 11.1347 16.3879 10.6634 16.0613 10.4086C15.7347 10.1539 15.2634 10.2121 15.0086 10.5387L16.1914 11.4613ZM11.1086 16.4613C11.3634 16.7879 11.8347 16.8461 12.1613 16.5914C12.4879 16.3366 12.5461 15.8653 12.2914 15.5387L11.1086 16.4613ZM8.39138 10.5387C8.13662 10.2121 7.66533 10.1539 7.33873 10.4086C7.01212 10.6634 6.95387 11.1347 7.20862 11.4613L8.39138 10.5387ZM10.95 16C10.95 16.4142 11.2858 16.75 11.7 16.75C12.1142 16.75 12.45 16.4142 12.45 16H10.95ZM12.45 5C12.45 4.58579 12.1142 4.25 11.7 4.25C11.2858 4.25 10.95 4.58579 10.95 5H12.45ZM4.125 15V16H5.625V15H4.125ZM4.125 16C4.125 18.0531 5.75257 19.75 7.8 19.75V18.25C6.61657 18.25 5.625 17.2607 5.625 16H4.125ZM7.8 19.75H15.6V18.25H7.8V19.75ZM15.6 19.75C17.6474 19.75 19.275 18.0531 19.275 16H17.775C17.775 17.2607 16.7834 18.25 15.6 18.25V19.75ZM19.275 16V15H17.775V16H19.275ZM12.2914 16.4613L16.1914 11.4613L15.0086 10.5387L11.1086 15.5387L12.2914 16.4613ZM12.2914 15.5387L8.39138 10.5387L7.20862 11.4613L11.1086 16.4613L12.2914 15.5387ZM12.45 16V5H10.95V16H12.45Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
                Detalle</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <ul class="max-w-lg space-y-2 list-inside">
          <li class="flex items-center text-red-500 font-semibold">
            <svg
              class="w-5 h-5 mr-2 text-red-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            No se encontraron datos para el rango de fechas seleccionado.
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-end items-center mt-4">
      <span class="mx-2">
        Mostrando {{ (pag - 1) * results + 1 }} -
        {{
          pag * results > filteredLavados.length
            ? filteredLavados.length
            : pag * results
        }}
        de {{ filteredLavados.length }} resultados
      </span>
      <button
        class="bg-[#3edfa9] hover:bg-gray-400 text-white font-regular mr-3 py-1 px-4 rounded shadow"
        @click="pag = pag - 1"
        :disabled="pag === 1"
      >
        Anterior
      </button>

      <button
        class="bg-[#3edfa9] hover:bg-gray-400 text-white font-regular py-1 px-4 rounded shadow"
        @click="pag = pag + 1"
        :disabled="pag === totalPages"
      >
        Siguiente
      </button>
    </div>
  </div>
  <Modal message="Registro" @confirm="deleteLavado" ref="modalComponent" />
</template>

<script>
import lavadoService from "../../composables/api/lavadoService.js";
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import Toast from "../Toast/Toast.vue";
import Modal from "../modalConfirmar/ModalConfirmar.vue";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

export default {
  components: {
    Toast,
    Modal,
  },
  name: "lavados",
  data() {
    return {
      results: 10,
      pag: 1,
    };
  },
  setup() {
    const lavados = ref([]);
    const startDate = ref("");
    const endDate = ref("");
    const showMessage = ref(false);
    const router = useRouter();
    const toastComponent = ref(null);
    const modalComponent = ref(null);
    const modal = ref();
    let sinData = ref([]);
    const results = ref(10);

    const exportToExcel = () => {
      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Obtener los datos a exportar
      const dataToExport = filteredLavados.value.map((lavado) => ({
        Fecha: formatDate(lavado.fecha),
        Descripción: lavado.descripcion,
        Servicio: lavado.servicio ? lavado.servicio.nombre : "Sin Servicio",
        MetodoDePago: lavado.metodoPago,
        Importe: lavado.importe,
      }));

      // Crear una hoja de trabajo
      const ws = XLSX.utils.json_to_sheet(dataToExport);

      // Agregar la hoja al libro
      XLSX.utils.book_append_sheet(wb, ws, "Lavados");

      // Exportar el libro de trabajo
      XLSX.writeFile(wb, "ventas.xlsx");
    };

    const generatePdf = (selectedLavadoId, clienteId) => {
      const doc = new jsPDF();

      // Seleccionamos los datos
      const selectedLavado = filteredLavados.value.find(
        (lavado) => lavado.id === selectedLavadoId
      );

      if (selectedLavado) {
        // Configura el grosor y color de la línea
        doc.setLineWidth(0.5);
        doc.setDrawColor("#CCCCCC");

        const x = 5; // coordenada x del rectángulo
        const y = 5; // coordenada y del rectángulo
        const width = 125; // ancho del rectángulo
        const height = 65; // alto del rectángulo
        const radius = 5; // radio de las esquinas redondeadas

        // Dibuja un rectángulo con esquinas redondeadas
        doc.roundedRect(x, y, width, height, radius, radius, "S");

        // Establece la posición inicial del texto
        const startY = 15; // posición y inicial del texto

        // Agrega el texto
        doc.text(`Fecha: ${formatDate(selectedLavado.fecha)}`, 10, startY);
        doc.text(
          `Cliente: ${selectedLavado.cliente.nombreApellido}`,
          10,
          startY + 10
        );
        doc.text(`Descripción: ${selectedLavado.descripcion}`, 10, startY + 20);
        doc.text(
          `Servicio: ${
            selectedLavado.servicio
              ? selectedLavado.servicio.nombre
              : "Sin Servicio"
          }`,
          10,
          startY + 30
        );
        doc.text(
          `Método de Pago: ${selectedLavado.metodoPago}`,
          10,
          startY + 40
        );
        doc.text(`Importe: $${selectedLavado.importe}`, 10, startY + 50);
      } else {
        doc.text("No se encontró el lavado seleccionado", 10, 10);
      }

      // Genera la salida
      const pdfBlob = doc.output("blob");

      // Genera la URL
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Abre el dialogo de impresion
      const printWindow = window.open(
        pdfUrl,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes"
      );

      // Abre el modulo de impresion y guardado
      printWindow.addEventListener("load", function () {
        printWindow.resizeTo(1080, 600);
        printWindow.print();
      });
    };

    const fetchLavados = async () => {
      try {
        const response = await lavadoService.getLavados();
        lavados.value = await response.sort((a, b) => {
          const fechaA = new Date(a.fecha);
          const fechaB = new Date(b.fecha);

          showMessage.value = true;
          setTimeout(() => {
            showMessage.value = false;
          }, 2000);

          return fechaB - fechaA;
        });
      } catch (error) {
        console.error(error);
      }
    };

    const formatDate = (date) => {
      const formattedDate = new Date(date).toLocaleString("es", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    };

    const filterLavados = () => {
      const filtered = lavados.value.filter((lavado) => {
        const lavadoDate = new Date(lavado.fecha);
        const start = startDate.value ? new Date(startDate.value) : null;
        const end = endDate.value ? new Date(endDate.value) : null;
        return (!start || lavadoDate >= start) && (!end || lavadoDate <= end);
      });
    };

    const filteredLavados = computed(() => {
      if (startDate.value || endDate.value) {
        return lavados.value.filter((lavado) => {
          const lavadoDate = new Date(lavado.fecha);
          const start = startDate.value ? new Date(startDate.value) : null;
          const end = endDate.value ? new Date(endDate.value) : null;
          if (end) {
            end.setDate(end.getDate() + 1);
          }
          return (!start || lavadoDate >= start) && (!end || lavadoDate <= end);
        });
      } else {
        return lavados.value;
      }
    });

    const paginatedLavados = computed(() => {
      const startIndex = (pag.value - 1) * 10;
      const endIndex = startIndex + 10;
      return filteredLavados.value.slice(startIndex, endIndex);
    });

    const pageChange = (newPage) => {
      pag.value = newPage;
    };

    const totalPages = computed(() => {
      return Math.ceil(filteredLavados.value.length / 10);
    });

    //Filtros
    const updateResults = (event) => {
      results.value = parseInt(event.target.value);
    };

    // Calcula el total de importe de los lavados mostrados
    const calculateTotalImporte = () => {
      return filteredLavados.value.reduce((total, lavado) => {
        return total + lavado.importe;
      }, 0);
    };
    const editLavado = (lavado) => {
      router.push({
        path: "/formlavado",
        query: { isAdmin: true, id: lavado.id },
      });
    };

    const deleteLavado = async (lavadoId) => {
      try {
        await lavadoService.deleteLavado(lavadoId);

        fetchLavados();
      } catch (error) {
        console.error(error);
      }
    };

    const deleteModal = async (lavadoId) => {
      modal.value = await modalComponent.value.getModal(lavadoId);
    };

    onMounted(async () => {
      await fetchLavados();
      await filterLavados();
    });

    return {
      lavados,
      startDate,
      endDate,
      showMessage,
      filteredLavados,
      router,
      toastComponent,
      modalComponent,
      modal,
      formatDate,
      filterLavados,
      calculateTotalImporte,
      fetchLavados,
      deleteLavado,
      editLavado,
      deleteModal,
      generatePdf,
      exportToExcel,
    };
  },
};
</script>

<style>
.viewport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

table {
  min-width: 100%;
}
</style>
