<template>
  <div class="grid grid-flow-row gap-4">
    <div class="flex justify-center min-height-auto">
      <h1
        class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
      >
        Reservas
      </h1>
    </div>

    <div class="flex justify-between items-center mb-4 flex-wrap">
      <!-- Cuadro de búsqueda -->
      <div class="w-full md:w-1/1 md:mb-5 xl:w-1/3 mb-4 md:mb-0">
        <input
          v-model="filtroNombre"
          type="text"
          placeholder="Buscar por nombre"
          class="px-4 py-2 border rounded-lg w-full"
        />
      </div>

      <!-- Contenedor para los botones -->
      <div class="w-full md:w-1/1 xl:w-2/3 flex space-x-2 justify-end">
        <div
          class="inline-grid lg:inline-flex w-full rounded-md shadow-sm gap-2 lg:justify-end"
          role="group"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#3edfa9] hover:bg-[#ffe068] rounded-lg"
          >
            <router-link :to="{ path: '/formreserva' }">
              <svg
                class="w-6 h-6 inline-block text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Agregar Reserva</router-link
            >
          </button>
          <button
            type="button"
            @click="fetchReservas"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#3edfa9] hover:bg-[#ffe068] rounded-lg"
          >
            <svg
              class="w-6 h-6 inline-block text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
              />
            </svg>
            Actualizar
          </button>
          <button
            type="button"
            @click="exportarPDF"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-[#ffe068] hover:bg-red-800 rounded-lg"
          >
            <svg
              class="w-6 h-6 inline-block text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            Exportar PDF
          </button>
        </div>
      </div>
    </div>

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
          <tr>
            <th @click="ordenar('nombre')" class="px-6 py-3 cursor-pointer">
              Nombre
            </th>
            <th @click="ordenar('dni')" class="px-6 py-3 cursor-pointer">
              Dni
            </th>
            <th @click="ordenar('celular')" class="px-6 py-3 cursor-pointer">
              Celular
            </th>
            <th @click="ordenar('email')" class="px-6 py-3 cursor-pointer">
              Email
            </th>
            <th @click="ordenar('servicio')" class="px-6 py-3 cursor-pointer">
              Servicio
            </th>
            <th
              @click="ordenar('tipoServicio')"
              class="px-6 py-3 cursor-pointer"
            >
              Tipo de Servicio
            </th>
            <!-- <th
              @click="ordenar('montoAbonado')"
              class="px-6 py-3 cursor-pointer"
            > -->
            <th
              @click="ordenar('montoTotal')"
              class="px-6 py-3 cursor-pointer"
            >
              Monto Abonado
            </th>
            <th @click="ordenar('fecha')" class="px-6 py-3 cursor-pointer">
              Fecha
            </th>
            <th @click="ordenar('hora')" class="px-6 py-3 cursor-pointer">
              Hora
            </th>
            <th @click="ordenar('estado')" class="px-6 py-3 cursor-pointer">
              Estado
            </th>
            <th class="px-6 py-3">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="reserva in reservasFiltradas"
            :key="reserva.id"
            class="bg-white border-b"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              {{ reserva.nombre }}
            </th>
            <td class="px-6 py-4">
              {{ reserva.dni ? reserva.dni : "Sin Datos" }}
            </td>
            <td class="px-6 py-4">{{ reserva.celular }}</td>
            <td class="px-6 py-4">{{ reserva.email }}</td>
            <td class="px-6 py-4">{{ reserva.servicio.nombre }}</td>
            <td class="px-6 py-4">
              {{
          reserva.servicio.tipoServicio
            ? reserva.servicio.tipoServicio.nombre
            : "Sin tipo de servicio"
        }}
            </td>
            <!--<td class="px-6 py-4">
              <template v-if="tipoServicio.length > 0">
                {{
                  tipoServicio.find(
                    (tipo) => tipo.id === reserva.servicio.tipoServicioId
                  )?.nombre || "Sin tipo de servicio"
                }}
              </template>
              <template v-else> Sin tipo de servicio </template>
            </td>-->
            <!-- <td class="px-6 py-4">
              {{ reserva.montoAbonado ? reserva.montoAbonado : "Sin Abono" }}
            </td> -->
            <td class="px-6 py-4">
              {{ reserva.montoTotal ? reserva.montoTotal : "Sin Abono" }}
            </td>
            <td class="px-6 py-4">
              {{ new Date(reserva.appointmentTime).toLocaleDateString() }}
            </td>
            <td class="px-6 py-4">
              {{ new Date(reserva.appointmentTime).toLocaleTimeString() }}
            </td>
            <td class="px-6 py-4">
              <span
                :class="estadoClase(reserva)"
                class="px-2 py-1 text-xs font-semibold rounded-lg"
              >
                {{ isReservaVencida(reserva) ? "Finalizada" : "Abierta" }}
              </span>
            </td>
            <td
              class="grid lg:flex md:flex px-2 md:px-6 py-4 lg:text-left md:text-center"
            >
              <router-link
                :to="{ path: '/formreserva', query: { id: reserva.id } }"
              >
                <button class="font-medium text-gray-800 hover:underline">
                  Editar
                </button>
              </router-link>
              <button
                @click="deleteModal(reserva.id)"
                class="font-medium text-red-500 hover:underline lg:ml-5"
              >
                Eliminar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Modal message="Reserva" @confirm="deleteReserva" ref="modalComponent" />
  </div>
</template>

<script>
import reservaService from "../../../composables/api/reservaService.js";
import { ref, onMounted, computed } from "vue";
import Modal from "../../modalConfirmar/ModalConfirmar.vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    Modal,
  },
  setup() {
    const reservas = ref([]);
    const modalComponent = ref(null);
    const modal = ref();
    const ordenarPor = ref("estado");
    const ordenAscendente = ref(true);
    const filtroNombre = ref("");
    const tipoServicio = ref([]);

    const fetchReservas = async () => {
      try {
        reservas.value = await reservaService.getReservations();
        ordenarReservas();
      } catch (error) {
        console.error("Error al obtener reservas:", error);
      }
    };

    const reservasFiltradas = computed(() => {
      return reservas.value.filter((reserva) =>
        reserva.nombre.toLowerCase().includes(filtroNombre.value.toLowerCase())
      );
    });

    const deleteReserva = async (reservaId) => {
      try {
        await reservaService.deleteReservation(reservaId);
        fetchReservas();
      } catch (error) {
        console.error(error);
      }
    };

    const deleteModal = async (reservaId) => {
      modal.value = await modalComponent.value.getModal(reservaId);
    };

    const isReservaVencida = (reserva) => {
      const appointmentDate = new Date(reserva.appointmentTime);
      const now = new Date();
      return appointmentDate < now;
    };

    const estadoClase = (reserva) => {
      return isReservaVencida(reserva)
        ? "bg-green-600 text-green-100"
        : "bg-blue-600 text-blue-100";
    };

    const ordenar = (columna) => {
      if (ordenarPor.value === columna) {
        ordenAscendente.value = !ordenAscendente.value;
      } else {
        ordenarPor.value = columna;
        ordenAscendente.value = true;
      }
      ordenarReservas();
    };

    const ordenarReservas = () => {
      reservas.value.sort((a, b) => {
        let modifier = ordenAscendente.value ? 1 : -1;

        if (ordenarPor.value === "nombre") {
          return a.nombre.localeCompare(b.nombre) * modifier;
        } else if (ordenarPor.value === "email") {
          return a.email.localeCompare(b.email) * modifier;
        } else if (ordenarPor.value === "servicio") {
          return a.servicio.nombre.localeCompare(b.servicio.nombre) * modifier;
        } else if (ordenarPor.value === "tipoServicio") {
          const tipoA = a.servicio.tipoServicio
            ? a.servicio.tipoServicio.nombre
            : "";
          const tipoB = b.servicio.tipoServicio
            ? b.servicio.tipoServicio.nombre
            : "";
          return tipoA.localeCompare(tipoB) * modifier;
        } else if (ordenarPor.value === "fecha") {
          return (
            (new Date(a.appointmentTime) - new Date(b.appointmentTime)) *
            modifier
          );
        } else if (ordenarPor.value === "hora") {
          return (
            (new Date(a.appointmentTime).getTime() -
              new Date(b.appointmentTime).getTime()) *
            modifier
          );
        } else if (ordenarPor.value === "estado") {
          return (isReservaVencida(a) - isReservaVencida(b)) * modifier;
        }
        return 0;
      });
    };

    const exportarPDF = () => {
      // Cambiar la orientación del PDF a 'landscape' (horizontal)
      const doc = new jsPDF("landscape");

      // Título del PDF
      doc.setFontSize(16); // Reducimos el tamaño del título
      doc.text("Reporte de Reservas", 14, 15); // Ajustamos el margen superior e izquierdo del título

      // Formatear los datos para la tabla
      const reservasData = reservasFiltradas.value.map((reserva) => [
        reserva.nombre,
        reserva.dni ? reserva.dni : "Sin Datos",
        reserva.celular,
        reserva.email,
        reserva.servicio.nombre,
        reserva.servicio.tipoServicio
          ? reserva.servicio.tipoServicio.nombre
          : "Sin tipo de servicio",
          // reserva.montoAbonado,
          reserva.montoTotal,
        `${new Date(reserva.appointmentTime).toLocaleDateString()} ${new Date(
          reserva.appointmentTime
        ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`, // Fecha y hora combinadas
      ]);

      // Crear la tabla utilizando autoTable
      doc.autoTable({
        head: [
          [
            "Nombre",
            "DNI",
            "Celular",
            "Email",
            "Servicio",
            "Tipo de Servicio",
            "Monto Abonado",
            "Fecha y Hora",
          ],
        ], // Eliminamos la columna Estado
        body: reservasData,
        startY: 25, // Empezar después del título
        theme: "grid", // Utilizar el tema grid
        styles: {
          fontSize: 9, // Tamaño de la fuente ajustado
          cellPadding: 2, // Espacio dentro de las celdas reducido
          halign: "center", // Centrar el contenido horizontalmente
          valign: "middle", // Centrar el contenido verticalmente
        },
        headStyles: {
          fillColor: [0, 102, 204], // Color de fondo del encabezado
          textColor: 255, // Color de texto blanco
          fontSize: 10, // Tamaño de fuente del encabezado ligeramente mayor
        },
        columnStyles: {
          0: { cellWidth: 40 }, // Ancho ajustado para la columna de Nombre
          1: { cellWidth: 30 }, // Ancho ajustado para DNI
          2: { cellWidth: 30 }, // Ancho ajustado para Celular
          3: { cellWidth: 50 }, // Ancho ajustado para Email
          4: { cellWidth: 50 }, // Ancho ajustado para Servicio
          5: { cellWidth: 30 }, // Ancho ajustado para Tipo de Servicio
          6: { cellWidth: 20 }, // Ancho ajustado para monto abonado
          7: { cellWidth: 40 }, // Fecha y Hora combinadas
        },
        margin: { top: 25, left: 5, right: 5 }, // Ajustamos los márgenes laterales para ganar espacio
        tableWidth: "wrap", // Para asegurar que la tabla no se desborde
        styles: {
          overflow: "linebreak", // Asegura que el texto que se desborde se divida en una nueva línea
        },
      });

      // Guardar el PDF
      doc.save("reporte_reservas.pdf");
    };

    onMounted(() => {
      fetchReservas();
    });

    return {
      reservasFiltradas,
      filtroNombre,
      reservas,
      ordenarPor,
      ordenAscendente,
      fetchReservas,
      modalComponent,
      modal,
      deleteModal,
      deleteReserva,
      isReservaVencida,
      estadoClase,
      ordenar,
      exportarPDF,
      tipoServicio,
    };
  },
};
</script>

<style scoped>
.bg-green-600 {
  background-color: #16a34a;
}

.text-green-100 {
  color: #d1fae5;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.text-blue-100 {
  color: #bfdbfe;
}

th.cursor-pointer {
  cursor: pointer;
}
</style>
