<template>
  <div class="grid grid-flow-row gap-4">
    <div class="flex justify-center min-height-auto">
      <h1
        class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
      >
        Panel General
      </h1>
    </div>
    <div class="flex items-center mb-4 text-sm text-green-800" role="alert">
      <svg
        class="flex-shrink-0 inline w-4 h-4 me-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div>En este panel puedes consultar los números de tu negocio.</div>
    </div>

    <div class="grid grid-cols-1 md:grid cols-2 lg:grid-cols-2 gap-2">
      <div class="block md:flex lg:flex justify-start gap-2">
        <div>
          <input
            type="date"
            v-model="fechaInicio"
            class="form-control w-full mb-2 lg:w-48 px-2 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <div>
          <input
            type="date"
            v-model="fechaFin"
            class="form-control w-full mb-2 lg:w-48 px-2 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
      </div>

      <div>
        <div
          class="inline-grid w-full lg:inline-flex rounded-md shadow-sm gap-3 mt-3 lg:mt-0"
          role="group"
        >
          <button
            type="button"
            @click="filtrar"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 rounded-lg bg-[#3edfa9] hover:bg-[#ffe068] text-white"
          >
            Filtrar
          </button>
          <button
            type="button"
            @click="exportarPDF"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 rounded-lg bg-red-600 hover:bg-red-400 text-white"
          >
            <svg
              class="w-5 h-5 mr-1 inline-block text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            Exportar PDF
          </button>
          <button
            type="button"
            @click="exportarapdf"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 rounded-lg bg-blue-600 hover:bg-blue-400 text-white"
          >
            <svg
              class="w-5 h-5 mr-1 inline-block text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            Exportar por Servicios
          </button>
        </div>
      </div>
    </div>

    <!--Spiner-->

    <div v-if="noDataFound">
      <ul class="max-w-lg space-y-2 list-inside">
        <li class="flex items-center text-red-500 font-semibold">
          <svg
            class="w-5 h-5 mr-2 text-red-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          No se encontraron datos para el rango de fechas seleccionado.
        </li>
      </ul>
    </div>

    <div v-if="errorMessage">
      <ul class="max-w-md space-y-2 list-inside">
        <li class="flex items-start text-red-500 font-semibold">
          <svg
            class="w-5 h-5 mr-2 text-red-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          {{ errorMessage }}
        </li>
      </ul>
    </div>

    <div v-if="isLoading">
      <ul class="max-w-md text-gray-500 list-inside">
        <li class="flex items-center">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-6 h-6 me-2 text-gray-300 font-md animate-spin fill-green-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Cargando...</span>
          </div>
          Cargando datos ...
        </li>
      </ul>
    </div>
    <!--Spiner-->

    <!--Cambiar Vista-->
    <div class="flex justify-end">
      <div class="inline-flex" role="group">
        <button
          type="button"
          @click="toggleCardsTable"
          class="inline-flex items-center px-2 py-2 text-md font-medium text-gray-700 hover:bg-gray-100"
        >
          <svg
            class="w-4 h-4 m-1"
            fill="#000000"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <title>list-filled</title>
              <path
                d="M30 15.25h-17c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h17c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM13 5.75h17c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-17c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM30 26.25h-17c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h17c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM4 1.25h2c1.519 0 2.75 1.231 2.75 2.75v2c0 1.519-1.231 2.75-2.75 2.75h-2c-1.519 0-2.75-1.231-2.75-2.75v-2c0-1.519 1.231-2.75 2.75-2.75zM3.997 12.25h2c1.519 0 2.75 1.231 2.75 2.75v2c0 1.519-1.231 2.75-2.75 2.75h-2c-1.519 0-2.75-1.231-2.75-2.75v-2c0-1.519 1.231-2.75 2.75-2.75zM3.997 23.25h2c1.519 0 2.75 1.231 2.75 2.75v2c0 1.519-1.231 2.75-2.75 2.75h-2c-1.519 0-2.75-1.231-2.75-2.75v-2c0-1.519 1.231-2.75 2.75-2.75z"
              ></path>
            </g>
          </svg>

          <svg
            class="w-4 h-4 m-1"
            viewBox="0 0 28 28"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <title>grid</title>
              <desc>Created with Sketch Beta.</desc>
              <defs></defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                sketch:type="MSPage"
              >
                <g
                  id="Icon-Set-Filled"
                  sketch:type="MSLayerGroup"
                  transform="translate(-104.000000, -935.000000)"
                  fill="#000000"
                >
                  <path
                    d="M128,935 L124,935 C121.791,935 120,936.791 120,939 L120,943 C120,945.209 121.791,947 124,947 L128,947 C130.209,947 132,945.209 132,943 L132,939 C132,936.791 130.209,935 128,935 L128,935 Z M128,951 L124,951 C121.791,951 120,952.791 120,955 L120,959 C120,961.209 121.791,963 124,963 L128,963 C130.209,963 132,961.209 132,959 L132,955 C132,952.791 130.209,951 128,951 L128,951 Z M112,951 L108,951 C105.791,951 104,952.791 104,955 L104,959 C104,961.209 105.791,963 108,963 L112,963 C114.209,963 116,961.209 116,959 L116,955 C116,952.791 114.209,951 112,951 L112,951 Z M112,935 L108,935 C105.791,935 104,936.791 104,939 L104,943 C104,945.209 105.791,947 108,947 L112,947 C114.209,947 116,945.209 116,943 L116,939 C116,936.791 114.209,935 112,935 L112,935 Z"
                    id="grid"
                    sketch:type="MSShapeGroup"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          Cambiar vista
        </button>
      </div>
    </div>
    <!--Cambiar Vista-->

    <div class="mb-4 border-b border-gray-200">
      <ul
        class="flex flex-wrap -mb-px text-md font-medium text-center"
        id="default-tab"
        data-tabs-toggle="#default-tab-content"
        role="tablist"
      >
        <li class="me-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 text-green-600 hover:text-green-900 hover:border-green-600"
            id="profile-tab"
            data-tabs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Inicio
          </button>
        </li>
        <li class="me-2" role="presentation">
          <button
            class="inline-block p-4 border-b-2 border-t-green-600 text-green-600 hover:text-green-900"
            id="dashboard-tab"
            data-tabs-target="#dashboard"
            type="button"
            role="tab"
            aria-controls="dashboard"
            aria-selected="false"
          >
            General
          </button>
        </li>
      </ul>
    </div>
    <div id="info-to-toggle">
      <div id="default-tab-content">
        <div
          class="hidden p-4"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div
            v-if="showCards"
            id="cards"
            class="grid grid-cols-1 md:grid-cols-2 lg-grid-cols-4 gap-3"
          >
            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
              data-tooltip-target="tooltip1"
            >
              <a
                href="#"
                id="boxSelect"
                @click="mostrarDetalle('Cantidad Ventas')"
              >
                <h2 class="text-lg font-semibold">Cantidad Ventas</h2>
                <p class="text-3xl">{{ totalLavados }}</p>
              </a>
            </div>
            <div
              id="tooltip1"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Click para mas detalle
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <!--Fin Card-->

            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
              data-tooltip-target="tooltip2"
            >
              <a href="#" @click="mostrarTotalFacturado()">
                <h2 class="text-lg font-semibold">Total Facturado</h2>
                <p class="text-3xl">{{ totalFacturado }}</p>
              </a>
            </div>
            <div
              id="tooltip2"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Click para mas detalle
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <!--Fin Card-->

            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
            >
              <a>
                <h2 class="text-lg font-semibold">Ingresos</h2>
                <p class="text-3xl">{{ totalIngresos }}</p>
              </a>
            </div>

            <!--Fin Card-->

            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
            >
              <a>
                <h2 class="text-lg font-semibold">Egresos</h2>
                <p class="text-3xl">{{ totalEgresos }}</p>
              </a>
            </div>
            <!--Fin Card-->
          </div>
          <div v-else id="table">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500"
              >
                <tbody>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Cantidad Ventas
                    </th>

                    <td class="px-6 py-4">
                      {{ totalLavados }}
                    </td>

                    <td class="px-6 py-4 text-right">
                      <a
                        href="#"
                        @click="mostrarDetalle('Cantidad Ventas')"
                        class="font-medium text-gray-600 hover:underline"
                        >Detalle</a
                      >
                    </td>
                  </tr>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Total Facturado
                    </th>
                    <td class="px-6 py-4">
                      {{ totalFacturado }}
                    </td>

                    <td class="px-6 py-4 text-right">
                      <a
                        href="#"
                        @click="mostrarTotalFacturado()"
                        class="font-medium text-gray-600 hover:underline"
                        >Detalle</a
                      >
                    </td>
                  </tr>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Ingresos
                    </th>
                    <td class="px-6 py-4">
                      {{ totalIngresos }}
                    </td>
                  </tr>
                  <tr class="bg-white hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Egresos
                    </th>
                    <td class="px-6 py-4">
                      {{ totalEgresos }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="hidden p-4"
          id="dashboard"
          role="tabpanel"
          aria-labelledby="dashboard-tab"
        >
          <div
            v-if="showCards"
            id="cards"
            class="grid grid-cols-1 md:grid-cols-2 lg-grid-cols-4 gap-3"
          >
            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
              data-tooltip-target="tooltip3"
            >
              <a href="#" id="boxSelect" @click="mostrarTotalFacturado()">
                <h2 class="text-lg font-semibold">Total facturado</h2>
                <p class="text-3xl">{{ totalFacturado }}</p>
              </a>
            </div>
            <div
              id="tooltip3"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Click para mas detalle
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <!--Fin Card-->

            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
              data-tooltip-target="tooltip4"
            >
              <a href="#" @click="mostrarTotalGastos()">
                <h2 class="text-lg font-semibold">Gastos</h2>
                <p class="text-3xl">{{ totalGastos }}</p>
              </a>
            </div>
            <div
              id="tooltip4"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Click para mas detalle
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <!--Fin Card-->

            <!--Inicio Card-->
            <div
              class="max-w-auto p-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
            >
              <a>
                <h2 class="text-lg font-semibold">Beneficio Neto</h2>
                <p class="text-3xl">{{ beneficioNeto }}</p>
              </a>
            </div>
            <!--Fin Card-->
          </div>
          <div v-else id="table">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500"
              >
                <tbody>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Total Facturado
                    </th>

                    <td class="px-6 py-4">
                      {{ totalFacturado }}
                    </td>

                    <td class="px-6 py-4 text-right">
                      <a
                        href="#"
                        @click="mostrarTotalFacturado()"
                        class="font-medium text-gray-600 hover:underline"
                        >Detalle</a
                      >
                    </td>
                  </tr>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Gastos
                    </th>
                    <td class="px-6 py-4">
                      {{ totalGastos }}
                    </td>

                    <td class="px-6 py-4 text-right">
                      <a
                        href="#"
                        @click="mostrarTotalGastos()"
                        class="font-medium text-gray-600 hover:underline"
                        >Detalle</a
                      >
                    </td>
                  </tr>
                  <tr class="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Beneficio Neto
                    </th>
                    <td class="px-6 py-4">
                      {{ beneficioNeto }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--<div id="tablaporservicios" class="relative overflow-x-auto">
            <div class="flex justify-center min-height-auto">
              <h1
                class="text-3xl mt-5 mb-5 text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
              >
                Reporte por servicios
              </h1>
            </div>
            <table
              class="w-full mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-sm text-white uppercase bg-[#3edfa9] dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Fecha
                  </th>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Tipo de Servicio
                  </th>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Servicio
                  </th>

                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Transfrencia
                  </th>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Efectivo
                  </th>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Gastos
                  </th>
                  <th scope="col" class="px-6 py-3 border-r border-white">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b">
                  <td rowspan="5" class="px-6 py-4 border-r">23/09/2024</td>
                  <td rowspan="5" class="px-6 py-4 border-r">
                    FORD RANGER 2013
                  </td>
                  <td class="px-6 py-4 border-r">Dunas</td>
                  <td class="px-6 py-4 border-r">4500</td>
                  <td class="px-6 py-4 border-r">0</td>
                  <td class="px-6 py-4">1000</td>
                  <td class="px-6 py-4">3500</td>
                </tr>

                <tr class="bg-white border-b">
                  <td class="px-6 py-4 border-r">Pómez Carachi</td>
                  <td class="px-6 py-4 border-r">0</td>
                  <td class="px-6 py-4 border-r">2000</td>
                  <td class="px-6 py-4">1000</td>
                  <td class="px-6 py-4">1000</td>
                </tr>

                <tr class="bg-white border-b">
                  <td class="px-6 py-4 border-r">Volcan Galan</td>
                  <td class="px-6 py-4 border-r">1500</td>
                  <td class="px-6 py-4 border-r">0</td>
                  <td class="px-6 py-4">1000</td>
                  <td class="px-6 py-4">500</td>
                </tr>
              </tbody>
            </table>
          </div>-->

        <!--
<div id="tablaporservicios" class="relative overflow-x-auto hidden ">
  <div class="flex justify-center min-height-auto">
      <h1
        class="text-3xl mt-5 mb-5 text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
      >
        Reporte por servicios 
      </h1>
    </div>
    <table class="w-full mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
        <thead class="text-sm text-white uppercase bg-[#3edfa9] dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 border-r border-white">
                  Fecha
                </th>
                <th scope="col" class="px-6 py-3 border-r border-white">
                  Servicio
                </th>
                <th scope="col" class="px-6 py-3 border-r border-white">
                  Tipo de Servicio
                </th>
                <th scope="col" class="px-6 py-3 border-r border-white">
                  Método de Pago
                </th>
                <th scope="col" class="px-6 py-3 border-r border-white">
                  Monto
                </th>                
            </tr>
        </thead>
        <tbody>
            <tr v-for="(lavado, index) in lavados" :key="index" class="bg-white border-b  dark:bg-gray-800 dark:border-gray-700">
              
                
                <td class="px-6 py-4 border-r">
                  {{ formatearFecha(lavado.fecha) }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ lavado.servicio.nombre }}
                </td>
                <td class="px-6 py-4 border-r">
                    {{ lavado.servicio.tipoServicio.nombre }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ lavado.metodoPago }}
                </td>
                <td class="px-6 py-4">
                  {{ lavado.importe }}
                </td>
                
                

            </tr>

            
            
        </tbody>
    </table>

    <div class="grid grid-cols-2 mt-5">
      <div><h1 class="text-2xl">Totales por fecha</h1>

<table class="mt-5 mb-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto w-fit mx-auto px-4">
  <thead class="text-sm text-white uppercase bg-[#3edfa9] dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" class="px-6 py-3 border-r border-white">
              Fecha
            </th>
            <th scope="col" class="px-6 py-3 border-r border-white">
              Subtotal( Trans + Efect )
            </th>
            <th scope="col" class="px-6 py-3 border-r border-white">
              Gastos
            </th>
            <th scope="col" class="px-6 py-3 border-r border-white">
              Total
            </th>
          </tr></thead>
          <tbody>
<tr class="bg-white border-b  dark:bg-gray-800 dark:border-gray-700" v-for="(monto, fecha) in montosPorFecha" :key="fecha">
<td class="px-6 py-4 border-r">{{ fecha }}</td>
<td class="px-6 py-4 border-r">{{ monto }}</td>
<td class="px-6 py-4 border-r">
    {{ groupedGastos[fecha] ? groupedGastos[fecha].total : 0 }}
  </td>
  <td class="px-6 py-4">
    {{ calculateMontoGasto(monto, fecha) || (groupedGastos[fecha] ? groupedGastos[fecha].total : 0) }}
  </td>

</tr> </tbody>
</table></div>
      <div class="hidden"></div>
    </div>
    
    

    
</div>-->

        <!--<table>
  <thead>
    <tr>
      <th>Fecha</th>
      <th>Servicio</th>
      <th>Tipo de Servicio</th>
      <th>Método de Pago</th>
      <th>Gastos Esta linea se comenta</th>
      <th>MontoMétodoPago</th>
      <th>Gastos por Fecha</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(lavado, index) in lavados" :key="index">
      <td>{{ formatearFecha(lavado.fecha) }}</td>
      <td>{{ lavado.servicio.nombre }}</td>
      <td>{{ lavado.servicio.tipoServicio.nombre }}</td>
      <td>{{ lavado.metodoPago }}</td>
      <td>{{ lavado.egresos ? lavado.egresos[0].importe : 0 }}</td> Esta linea se comenta
      <td>{{ lavado.importe }}</td>
      <td>
  {{ index === groupedGastos[lavado.fecha].firstRecordIndex ? groupedGastos[lavado.fecha].total : (lavado.tipo === 'gasto' && lavado.importe !== 0 ? lavado.importe : '') }}
</td>

    </tr>
  </tbody>
</table>-->
        <div id="tablaporservicios" class="relative overflow-x-auto hidden">
          <div class="flex justify-center min-height-auto">
            <h1
              class="text-3xl mt-5 mb-5 text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl"
            >
              Reporte por servicios
            </h1>
          </div>
          <table
            class="w-full mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-sm text-white uppercase bg-[#3edfa9] dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Tipo de Servicio
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Servicio
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Fecha
                </th>

                <th scope="col" class="px-3 py-3 border-r border-white">
                  Transfrencia
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Efectivo
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Gastos
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Efec+Transf
                </th>
                <th scope="col" class="px-3 py-3 border-r border-white">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(servicio, index) in agrupadosPorTipoServicio"
                :key="index"
              >
                <tr>
                  <td
                    :rowspan="servicio.servicios.length"
                    class="px-3 py-4 border-r border-b"
                  >
                    {{ servicio.tipoServicioNombre }}
                  </td>
                  <td class="px-3 py-4 border-r border-b">
                    {{ servicio.servicios[0].servicioNombre }}
                  </td>
                  <td
                    :rowspan="servicio.servicios.length"
                    class="px-3 py-4 border-r border-b"
                  >
                    {{ formatDate(servicio.fecha) }}
                  </td>
                  <td class="px-3 py-4 border-r border-b">
                    {{ servicio.montos[0].transferencia }}
                  </td>
                  <td class="px-3 py-4 border-r border-b">
                    {{ servicio.montos[0].efectivo }}
                  </td>
                  <!--Gastos-->
                  <td
                    :rowspan="servicio.servicios.length"
                    class="px-3 py-4 border-r border-b"
                  >
                    {{
                      gastosAgrupados[servicio.fecha] &&
                      Object.keys(gastosAgrupados[servicio.fecha]).length > 0 &&
                      Object.values(gastosAgrupados[servicio.fecha]).some((b) =>
                        b.some(
                          (gasto) =>
                            gasto.fechaRegistro.split("T")[0] ===
                              servicio.fecha &&
                            gasto.isGasto &&
                            gasto.tipoServicioId === servicio.tipoServicioId
                        )
                      )
                        ? Object.values(gastosAgrupados[servicio.fecha]).reduce(
                            (a, b) =>
                              a +
                              b
                                .filter(
                                  (gasto) =>
                                    gasto.fechaRegistro.split("T")[0] ===
                                      servicio.fecha &&
                                    gasto.isGasto &&
                                    gasto.tipoServicioId ===
                                      servicio.tipoServicioId
                                )
                                .reduce((c, d) => c + d.importe, 0),
                            0
                          )
                        : 0
                    }}
                  </td>
                  <!--Efect+Trans-->
                  <td
                    :rowspan="servicio.servicios.length"
                    class="px-6 py-4 border-r border-b"
                  >
                    {{ servicio.montos.reduce((a, b) => a + b.total, 0) }}
                  </td>

                  <!--Total-->
                  <td
                    :rowspan="servicio.servicios.length"
                    class="px-6 py-4 border-r border-b"
                  >
                    {{
                      servicio.montos.reduce((a, b) => a + b.total, 0) -
                      (gastosAgrupados[servicio.fecha] &&
                      Object.keys(gastosAgrupados[servicio.fecha]).length > 0 &&
                      Object.values(gastosAgrupados[servicio.fecha]).some((b) =>
                        b.some(
                          (gasto) =>
                            gasto.fechaRegistro.split("T")[0] ===
                              servicio.fecha &&
                            gasto.isGasto &&
                            gasto.tipoServicioId === servicio.tipoServicioId
                        )
                      )
                        ? Object.values(gastosAgrupados[servicio.fecha]).reduce(
                            (a, b) =>
                              a +
                              b
                                .filter(
                                  (gasto) =>
                                    gasto.fechaRegistro.split("T")[0] ===
                                      servicio.fecha &&
                                    gasto.isGasto &&
                                    gasto.tipoServicioId ===
                                      servicio.tipoServicioId
                                )
                                .reduce((c, d) => c + d.importe, 0),
                            0
                          )
                        : 0)
                    }}
                  </td>
                </tr>
                <template
                  v-for="(s, indexS) in servicio.servicios.slice(1)"
                  :key="indexS"
                >
                  <tr>
                    <td class="px-6 py-4 border-r border-b">
                      {{ s.servicioNombre }}
                    </td>
                    <td class="px-6 py-4 border-r border-b">
                      {{ servicio.montos[indexS + 1].transferencia }}
                    </td>
                    <td class="px-6 py-4 border-r border-b">
                      {{ servicio.montos[indexS + 1].efectivo }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: !mostrarVentanaDetalle }"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-24 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ kpiSeleccionado }}
          </h3>
          <div class="mt-5">
            <p class="text-gray-700">{{ detalleKPI }}</p>
          </div>

          <div class="bg-white shadow-md rounded-md p-6">
            <canvas id="chart"></canvas>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#3edfa9] text-base font-medium text-white hover:bg-[#ffe068] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="mostrarVentanaDetalle = false"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--Ventana emergente para el detalle del KPI //Comentario-->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: !mostrarImportesTotales }"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-24 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div
          class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-auto alto"
        >
          <!--alto clase para altura del modal-->
          <div class="overflow-x-scroll">
            <table
              class="table table-responsive table-hover table-striped table-sm overflow-x-auto"
            >
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Responsable</th>
                  <th>Descripción</th>
                  <th>Importe</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="lavado in lavados" :key="lavado.id">
                  <td>
                    {{ lavado.fecha }}
                  </td>
                  <td>{{ lavado.responsable }}</td>
                  <td>{{ lavado.descripcion }}</td>
                  <td>{{ Number(lavado.importe) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right" @click="mostrarTotalFacturado()">
            <h2 class="mt-2 mr-2 text-lg font-semibold">
              Total: {{ totalFacturado }}$
            </h2>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#3edfa9] text-base font-medium text-white hover:bg-[#ffe068]"
              @click="mostrarImportesTotales = false"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: !mostrarGastos }"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-24 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div
          class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-auto h-96"
        >
          <table
            class="table table-responsive table-hover table-striped table-sm"
          >
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Descripción</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="gasto in gastos" :key="gasto.id">
                <td>
                  {{ gasto.fechaRegistro }}
                </td>
                <td>{{ gasto.descripcion }}</td>
                <td>{{ Number(gasto.importe) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-right">
            <p class="mt-2 mr-2 text-lg font-semibold">
              Total: {{ totalGastos }}$
            </p>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#3edfa9] text-base font-medium text-white hover:bg-[#ffe068]"
              @click="mostrarGastos = false"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.alto {
  height: 500px;
}

/*.container {
  width: 800px;
  margin: auto;
  margin-top: 20px;
}
.lbl-menu label {
  display: inline-block;
  padding: 20px;
  color: #000000;
  cursor: pointer;
  transition: all 400ms ease;
}
.lbl-menu label:hover {
  color: #3edfa9;
}
.content {
  margin-bottom: 200px;
  position: relative;
}
.content div {
  position: absolute;
  line-height: 1.8;
  /*transition: all 600ms ease;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  padding: 30px;
  background: #fff;
  padding-bottom: 40px;
}
#radio1,
#radio2 {
  display: none;
}
#radio1:checked ~ .tab1,
#radio2:checked ~ .tab2 {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.content div:after {
  position: absolute;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  bottom: 100%;
  left: 28px;
}
.content .tab2:after {
  left: 122px;
}*/
</style>

<script>
import Chart from "chart.js/auto";
import { computed, reactive, onMounted, ref } from "vue";
import adminService from "../../composables/api/adminService.js";
import { initFlowbite } from "flowbite";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import egresoService from "../../composables/api/egresoService.js";

export default {
  setup() {
    let mostrarVentanaDetalle = ref(false);
    let kpiSeleccionado = ref(null);
    let detalleKPI = ref("");
    let lavados = ref([]);
    let ingresos = ref([]);
    let egresos = ref([]);
    let beneficioNeto = ref(0);
    let totalLavados = ref(0);
    let totalIngresos = ref(0);
    let totalEgresos = ref(0);
    let fechaInicio = ref("");
    let fechaFin = ref("");
    let totalGastos = ref(0);
    let gastos = ref([]);
    let totalFacturado = ref(0);
    let mostrarImportesTotales = ref(false);
    let mostrarGastos = ref(false);
    let isLoading = ref(false);
    let noDataFound = ref();
    let errorMessage = ref("");
    let transferPaymentsTable = ref([]);
    let paymentsTable = ref([]);
    const agrupados = ref({});
    const agrupadosPorTipoServicio = ref({});
    let gastosAgrupados = ref({});

    const agruparPorTipoServicio = (lavados) => {
      const agrupados = {};

      lavados.forEach((lavado) => {
        if (lavado.tipoServicioId && lavado.servicio) {
          const tipoServicioNombre = lavado.servicio.tipoServicio
            ? lavado.servicio.tipoServicio.nombre
            : "No especificado";
          const servicioNombre = lavado.servicio.nombre
            ? lavado.servicio.nombre
            : "No especificado";

          const tipoServicio = lavado.tipoServicioId;
          const servicioId = lavado.servicioId;
          const fecha = lavado.fecha.split("T")[0];
          const clave = `${tipoServicio}_${fecha}`;
          if (!agrupados[clave]) {
            agrupados[clave] = {
              tipoServicioId: tipoServicio,
              tipoServicioNombre: tipoServicioNombre,
              fecha: fecha,
              servicios: [],
              montos: [],
              total: 0, // Agregamos una propiedad total
            };
          }
          agrupados[clave].servicios.push({
            servicioId: servicioId,
            servicioNombre: servicioNombre,
            descripcion: lavado.descripcion,
          });
          const monto = {
            servicioId: servicioId,
            servicioNombre: servicioNombre,
            transferencia: 0,
            efectivo: 0,
            total: 0,
          };
          if (lavado.metodoPago === "transferencia") {
            monto.transferencia += lavado.importe;
          } else if (lavado.metodoPago === "efectivo") {
            monto.efectivo += lavado.importe;
          }
          monto.total = monto.transferencia + monto.efectivo;
          agrupados[clave].montos.push(monto);
          agrupados[clave].total += monto.total; // Actualizamos el total
        }
      });

      return Object.values(agrupados);
    };

    const agruparGastosPorTipoServicio = (gastos) => {
      const agrupados = {};

      gastos.forEach((gasto) => {
        const fecha = gasto.fechaRegistro.split("T")[0];

        if (!agrupados[fecha]) {
          agrupados[fecha] = 0;
        }

        agrupados[fecha] += gasto.importe;
      });

      return agrupados;
    };

    /*const filtrar = async () => {
      isLoading.value = true;
      let datos = await adminService.getDatosPorFecha(
        fechaInicio.value,
        fechaFin.value
      );
      beneficioNeto.value = datos.beneficioNeto;
      totalLavados.value = datos.cantidadLavados;
      totalIngresos.value = datos.totalIngresos;
      totalEgresos.value = datos.totalEgresos;
      lavados.value = datos.lavados;
      ingresos.value = datos.ingresos;
      egresos.value = datos.egresos;
      totalGastos.value = datos.totalGastos;
      gastos.value = datos.gastos;
      totalFacturado.value = datos.totalFacturado;

      formatearLavados();

      mostrarGrafico();

      if (totalLavados.value > 0) {
        const boxSelect = document.querySelector("#boxSelect");
        boxSelect.classList.add("boxs");
      }
      isLoading.value = false;
    };*/

    //Para tabla
    const montosPorMetodoPago = computed(() => {
      const montos = {};
      lavados.value.forEach((lavado) => {
        const metodoPago = lavado.metodoPago || "Sin especificar";
        if (metodoPago === "transferencia" || metodoPago === "efectivo") {
          if (!montos[metodoPago]) {
            montos[metodoPago] = [];
          }
          montos[metodoPago].push({
            fecha: lavado.fecha,
            importe: lavado.importe,
          });
        }
      });
      return montos;
    });

    const totalMontos = computed(() => {
      let total = 0;
      Object.values(montosPorMetodoPago.value).forEach((metodoPagoArray) => {
        total += metodoPagoArray.reduce((acc, item) => acc + item.importe, 0);
      });
      return total;
    });

    const montosPorFecha = computed(() => {
      const montosFecha = {};
      Object.values(montosPorMetodoPago.value).forEach((metodoPagoArray) => {
        metodoPagoArray.forEach((item) => {
          const fecha = item.fecha;
          if (!montosFecha[fecha]) {
            montosFecha[fecha] = 0;
          }
          montosFecha[fecha] += item.importe;
        });
      });
      return montosFecha;
    });
    //Para tabla

    const filtrar = async () => {
      if (!fechaInicio.value || !fechaFin.value) {
        //console.log("Selecciones una fecha inicial y una fecha final");
        errorMessage.value = "Selecciones una fecha inicial y una fecha final";
        return;
      }

      const lavadosPorServicio = agruparPorServicio();
      agrupados.value = lavadosPorServicio;
      console.log(agrupados.value);

      //const lavadosPorServicio = agruparPorServicio();

      let datosPorFecha = await adminService.getDatosPorFecha(
        fechaInicio.value,
        fechaFin.value
      );
      const lavadosPorTipoServicio = agruparPorTipoServicio(
        datosPorFecha.lavados
      );
      agrupadosPorTipoServicio.value = lavadosPorTipoServicio;

      console.log("egresos", datosPorFecha.egresos);
      // gastosAgrupados.value = datosPorFecha.egresos

      datosPorFecha.egresos.forEach((gasto) => {
        const fecha = gasto.fechaRegistro.split("T")[0]; // Extrae solo la fecha, sin la hora
        const tipoServicioId = gasto.tipoServicioId || "Sin Tipo"; // Maneja casos de null o undefined

        // Si la fecha no existe como clave en gastosAgrupados, inicializa como un objeto vacío
        if (!gastosAgrupados.value[fecha]) {
          gastosAgrupados.value[fecha] = {};
        }

        // Si el tipoServicioId no existe como clave dentro de la fecha, inicializa como un arreglo vacío
        if (!gastosAgrupados.value[fecha][tipoServicioId]) {
          gastosAgrupados.value[fecha][tipoServicioId] = [];
        }

        // Agrega el gasto al arreglo correspondiente a su fecha y tipoServicioId
        gastosAgrupados.value[fecha][tipoServicioId].push(gasto);
      });

      console.log("gastos agrupados", gastosAgrupados.value);
      // this.gastosAgrupados = this.agruparGastosPorFecha(datosPorFecha.egresos);

      console.log(agrupadosPorTipoServicio.value);

      if (fechaFin.value < fechaInicio.value) {
        //console.log("La fecha final no puede ser menor a la fecha inicial!");
        errorMessage.value =
          "La fecha final no puede ser menor a la fecha inicial!";
        return;
      }

      errorMessage.value = "";

      isLoading.value = true;
      let datos = await adminService.getDatosPorFecha(
        fechaInicio.value,
        fechaFin.value
      );

      if (
        datos.lavados.length === 0 &&
        datos.ingresos.length === 0 &&
        datos.egresos.length === 0
      ) {
        //alert("No se encontraron datos para el rango de fechas seleccionado.");
        noDataFound.value = true;
      } else {
        noDataFound.value = false;
        beneficioNeto.value = datos.beneficioNeto;
        totalLavados.value = datos.cantidadLavados;
        totalIngresos.value = datos.totalIngresos;
        totalEgresos.value = datos.totalEgresos;
        lavados.value = datos.lavados;
        ingresos.value = datos.ingresos;
        egresos.value = datos.egresos;
        totalGastos.value = datos.totalGastos;
        gastos.value = datos.gastos;
        totalFacturado.value = datos.totalFacturado;

        formatearLavados();

        mostrarGrafico();

        if (totalLavados.value > 0) {
          const boxSelect = document.querySelector("#boxSelect");
          boxSelect.classList.add("boxs");
        }
      }

      isLoading.value = false;
    };

    const formatearLavados = () => {
      lavados.value = lavados.value.map((lavado) => {
        return {
          ...lavado,
          fecha: formatearFecha(lavado.fecha),
        };
      });
      gastos.value = gastos.value.map((gasto) => {
        return {
          ...gasto,
          fechaRegistro: formatearFecha(gasto.fechaRegistro),
        };
      });
    };
    const formatearFecha = (fecha) => {
      return `${fecha.split("T")[0].split("-")[2]}/${
        fecha.split("T")[0].split("-")[1]
      }/${fecha.split("T")[0].split("-")[0]}`;
    };

    const formatDate = (date) => {
      const fecha = new Date(date);
      return `${fecha.getUTCDate().toString().padStart(2, "0")}/${(
        fecha.getUTCMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${fecha.getUTCFullYear()}`;
    };

    const obtenerDetalleKPI = (kpi) => {
      const fechaInicioFormateada = formatearFecha(fechaInicio.value);
      const fechaFinFormateada = formatearFecha(fechaFin.value);

      if (kpi === "totalLavados") {
        return `La cantidad de ventas realizadas entre ${fechaInicioFormateada} y ${fechaFinFormateada} es de ${totalLavados.value}.`;
      } else if (kpi === "ingresos") {
        return `Los ingresos entre ${fechaInicioFormateada} y ${fechaFinFormateada} son de $${totalIngresos.value}.`;
      } else if (kpi === "egresos") {
        return `Los egresos entre ${fechaInicioFormateada} y ${fechaFinFormateada} son de $${totalEgresos.value}.`;
      } else if (kpi === "beneficioNeto") {
        return `El beneficio neto entre ${fechaInicioFormateada} y ${fechaFinFormateada} es de $${beneficioNeto.value}.`;
      }
    };
    const mostrarTotalFacturado = () => {
      if (lavados.value != 0) {
        mostrarImportesTotales.value = true;
      }
    };
    const mostrarTotalGastos = () => {
      if (gastos.value != 0) {
        mostrarGastos.value = true;
      }
    };
    const mostrarDetalle = (kpi) => {
      kpiSeleccionado.value = kpi;

      // Asigna el detalle del KPI seleccionado a la variable detalleKPI
      detalleKPI.value = obtenerDetalleKPI(kpi); // Función ficticia para obtener el detalle del KPI
      if (totalLavados.value > 0) {
        mostrarVentanaDetalle.value = true;
        return;
      }
    };
    const datosGrafico = () => {
      const lavadosAgrupados = lavados.value.reduce((acumulador, lavado) => {
        const fecha = lavado.fecha;

        if (acumulador[fecha]) {
          acumulador[fecha] += 1;
        } else {
          acumulador[fecha] = 1;
        }

        return acumulador;
      }, {});
      let cantLavados = lavados.value.length;
      return {
        labels: Object.keys(lavadosAgrupados),
        datasets: [
          {
            label: "Cantidad de Ventas",
            data: Object.values(lavadosAgrupados),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      };
    };

    const mostrarGrafico = () => {
      const ctx = document.getElementById("chart").getContext("2d");
      const existingChart = Chart.getChart("chart");

      if (existingChart) {
        existingChart.destroy();
      }
      console.log(datosGrafico());
      new Chart(ctx, {
        type: "line",
        data: datosGrafico(),
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };
    const agruparPorMetodoPago = () => {
      return lavados.value.reduce((grupos, lavado) => {
        const metodoPago = lavado.metodoPago || "Sin especificar";
        if (!grupos[metodoPago]) {
          grupos[metodoPago] = [];
        }
        grupos[metodoPago].push(lavado);
        return grupos;
      }, {});
    };

    const agruparPorServicio = () => {
      const agrupados = {};
      lavados.value.forEach((lavado) => {
        const servicio = lavado.servicioId;
        const fecha = lavado.fecha.split("T")[0];
        if (!agrupados[fecha]) {
          agrupados[fecha] = {};
        }
        if (!agrupados[fecha][servicio]) {
          agrupados[fecha][servicio] = {
            total: 0,
            servicios: [],
          };
        }
        agrupados[fecha][servicio].total += lavado.importe;
        agrupados[fecha][servicio].servicios.push({
          servicioId: lavado.servicioId,
          importe: lavado.importe,
        });
      });
      return agrupados;
    };

    //Reporte por servicios
    const exportarPorServicios = () => {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text("Reporte de Ventas por Servicio", 20, 20);

      // Información general
      const fechaInicioArg = `${fechaInicio.value.split("-")[2]}/${
        fechaInicio.value.split("-")[1]
      }/${fechaInicio.value.split("-")[0]}`;
      const fechaFinArg = `${fechaFin.value.split("-")[2]}/${
        fechaFin.value.split("-")[1]
      }/${fechaFin.value.split("-")[0]}`;
      doc.setFontSize(12);
      doc.text(`Fecha de inicio: ${fechaInicioArg}`, 20, 30);
      doc.text(`Fecha de fin: ${fechaFinArg}`, 20, 40);
      doc.text(`Total Ventas: $${totalFacturado.value}`, 20, 50);

      // Detalle de Ventas agrupadas por Servicio
      let yPosition = 60;
      const lavadosPorServicio = agruparPorServicio();
      console.log(lavadosPorServicio);
      Object.keys(lavadosPorServicio).forEach((servicio) => {
        // Añadir el título del servicio
        doc.setFontSize(12);
        //doc.text(`Servicio: ${servicio}`, 20, yPosition);
        yPosition += 10;

        const pagosPorFecha = {};

        lavadosPorServicio[servicio].forEach((lavado, index) => {
          if (yPosition > 280) {
            doc.addPage();
            yPosition = 20;
          }

          const fechaFormateada = formatearFecha(lavado.fecha);

          if (!pagosPorFecha[fechaFormateada]) {
            pagosPorFecha[fechaFormateada] = {
              servicio: lavado.servicio,
              tipoServicio: lavado.tipoServicio,
              transferencias: 0,
              efectivo: 0,
              gastos: 0,
            };
          }

          if (lavado.metodoPago === "transferencia") {
            pagosPorFecha[fechaFormateada].transferencias += lavado.importe;
          } else if (lavado.metodoPago === "efectivo") {
            pagosPorFecha[fechaFormateada].efectivo += lavado.importe;
          }

          let totalGast = 0;
          console.log(lavados);
          if (lavado.egresos != null && lavado.egresos.length > 0) {
            lavado.egresos.forEach((egreso) => {
              totalGast += egreso.importe;
            });
          }
          pagosPorFecha[fechaFormateada].gastos += totalGast;
        });

        // Imprimir totales por fecha
        Object.keys(pagosPorFecha).forEach((fecha) => {
          const totalTransferencias = pagosPorFecha[fecha].transferencias;
          const totalEfectivo = pagosPorFecha[fecha].efectivo;
          const totalGast = pagosPorFecha[fecha].gastos;
          const total = totalTransferencias + totalEfectivo - totalGast;

          const fechaFormateada = new Date(fecha).toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          doc.setFontSize(10);
          doc.text(`Total para ${fechaFormateada}:`, 20, yPosition);
          doc.autoTable({
            head: [
              [
                "Servicio",
                "Tipo de Servicio",
                "Transferencias",
                "Efectivo",
                "Gastos",
                "Total",
              ],
            ],
            body: [
              [
                pagosPorFecha[fecha].servicio,
                pagosPorFecha[fecha].tipoServicio,
                `$${totalTransferencias}`,
                `$${totalEfectivo}`,
                `$${totalGast}`,
                `$${total}`,
              ],
            ],
            startY: yPosition + 10,
          });
          yPosition += 30;
        });

        yPosition += 10; // Espacio adicional entre grupos
      });

      // Guardar el PDF con un nombre
      doc.save("reporte_por_servicios.pdf");
    };

    //Reporte por servicios

    // Función para exportar datos a PDF
    const exportarPDF = () => {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text("Reporte de Ventas", 20, 20);

      // Formatear las fechas en línea
      const fechaInicioArg = `${fechaInicio.value.split("-")[2]}/${
        fechaInicio.value.split("-")[1]
      }/${fechaInicio.value.split("-")[0]}`;
      const fechaFinArg = `${fechaFin.value.split("-")[2]}/${
        fechaFin.value.split("-")[1]
      }/${fechaFin.value.split("-")[0]}`;

      // Información general
      doc.setFontSize(12);
      doc.text(`Fecha de inicio: ${fechaInicioArg}`, 20, 30);
      doc.text(`Fecha de fin: ${fechaFinArg}`, 20, 40);
      doc.text(`Total Ventas: $${totalFacturado.value}`, 20, 50);
      doc.text(`Total Ingresos: $${totalIngresos.value}`, 20, 60);
      doc.text(`Total Egresos: $${totalEgresos.value}`, 20, 70);
      doc.text(`Beneficio Neto: $${beneficioNeto.value}`, 20, 80);

      // Detalle de Lavados (Ventas) agrupados por Método de Pago
      doc.setFontSize(14);
      doc.text("Detalle de Ventas:", 20, 100);

      let yPosition = 110;
      const lavadosPorMetodoPago = agruparPorMetodoPago();

      Object.keys(lavadosPorMetodoPago).forEach((metodoPago) => {
        // Añadir el título del método de pago
        doc.setFontSize(12);
        doc.text(`Método de Pago: ${metodoPago}`, 20, yPosition);
        yPosition += 10;

        lavadosPorMetodoPago[metodoPago].forEach((lavado, index) => {
          if (yPosition > 280) {
            doc.addPage();
            yPosition = 20;
          }
          doc.setFontSize(10);
          doc.text(
            `${index + 1}. Fecha: ${lavado.fecha}, Responsable: ${
              lavado.responsable
            }, Descripción: ${lavado.descripcion}, Importe: $${lavado.importe}`,
            20,
            yPosition,
            { maxWidth: 170 }
          );
          yPosition += 10;
        });

        yPosition += 10; // Espacio adicional entre grupos
      });

      // Detalle de Gastos
      yPosition += 10;
      doc.setFontSize(14);
      doc.text("Detalle de Gastos:", 20, yPosition);
      yPosition += 10;

      gastos.value.forEach((gasto, index) => {
        if (yPosition > 280) {
          doc.addPage();
          yPosition = 20;
        }
        doc.setFontSize(10);
        doc.text(
          `${index + 1}. Fecha: ${gasto.fechaRegistro}, Descripción: ${
            gasto.descripcion
          }, Importe: $${gasto.importe}`,
          20,
          yPosition
        );
        yPosition += 10;
      });

      // Guardar el PDF con un nombre
      doc.save("reporte_ventas.pdf");
    };

    onMounted(() => {
      initFlowbite();
    });

    return {
      fechaInicio,
      fechaFin,
      mostrarVentanaDetalle,
      kpiSeleccionado,
      detalleKPI,
      lavados,
      ingresos,
      egresos,
      beneficioNeto,
      totalLavados,
      totalIngresos,
      totalEgresos,
      datosGrafico,
      mostrarGrafico,
      mostrarDetalle,
      obtenerDetalleKPI,
      filtrar,
      totalGastos,
      gastos,
      totalFacturado,
      mostrarImportesTotales,
      mostrarTotalFacturado,
      formatearLavados,
      mostrarGastos,
      mostrarTotalGastos,
      isLoading,
      noDataFound,
      exportarPDF,
      exportarPorServicios,
      errorMessage,
      montosPorMetodoPago,
      totalMontos,
      montosPorFecha,
      agrupados,
      agrupadosPorTipoServicio,
      gastosAgrupados,
      agruparGastosPorTipoServicio,
      formatDate,
    };
  },
  name: "LavadosTable",
  props: {},
  components: {},
  created() {},
  data() {
    return {
      showCards: true,
      gastos: [],
    };
  },
  methods: {
    agruparGastosPorFecha(gastos) {
      const agrupados = {};

      gastos.forEach((gasto) => {
        const fecha = gasto.fechaRegistro.split("T")[0];

        if (!agrupados[fecha]) {
          agrupados[fecha] = [];
        }

        agrupados[fecha].push(gasto);
      });

      return agrupados;
    },

    calculateMontoGasto(monto, fecha) {
      if (!this.groupedGastos || !this.groupedGastos[fecha]) return monto;
      const totalGastos = this.groupedGastos[fecha].total;
      const lavadosForFecha = this.lavados.filter(
        (lavado) => lavado.fecha === fecha
      );
      const gasto = lavadosForFecha.reduce((acumulador, lavado) => {
        if (lavado.tipo === "gasto" && lavado.importe !== 0) {
          return acumulador + lavado.importe;
        }
        return acumulador;
      }, 0);
      return monto - (totalGastos + gasto);
    },
    gastoValue() {
      return lavado.importe - groupedGastos[lavado.fecha].total;
    },

    exportarapdf() {
  console.log("ExportToPdf method called");
  const div = document.getElementById("tablaporservicios");
  if (!div) {
    console.error("Could not find #tablaporservicios element");
    return;
  }
  
  div.classList.remove("hidden");
  html2canvas(div, {
    useCORS: true,
    logging: true,
  })
    .then((canvas) => {
      console.log("Canvas created");
      const doc = new jsPDF({
        orientation: "portrait", // Orientación vertical
        unit: "mm",
        format: "a4",
      });
      
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      
      // Definir márgenes
      const margin = 20; // 4 cm en mm
      const usableWidth = pageWidth - 2 * margin; // Ancho utilizable
      const usableHeight = pageHeight - margin; // Alto utilizable

      // Calcular el nuevo tamaño de la imagen para que se ajuste a la página con márgenes
      const scale = Math.min(usableWidth / imgWidth, usableHeight / imgHeight);
      const newWidth = imgWidth * scale;
      const newHeight = imgHeight * scale;

      const imgData = canvas.toDataURL("image/jpeg");
      // Centrar la imagen en la página con márgenes
      const xOffset = margin; // Margen izquierdo
      const yOffset = margin; // Margen superior

      doc.addImage(imgData, "JPEG", xOffset, yOffset, newWidth, newHeight, {
        compression: "MEDIUM",
      });

      doc.save("reportServicios.pdf");
    })
    .catch((error) => {
      console.error("Error creating canvas:", error);
    });
  
  div.classList.add("hidden");
},

    formatearFecha(fecha) {
      if (!fecha) {
        return ""; // o algún otro valor por defecto
      }
      const [dia, mes, ano] = fecha.split("/");
      const date = new Date(`${ano}-${mes}-${dia}`);
      if (isNaN(date.getTime())) {
        return ""; // o algún otro valor por defecto
      }
      return `${dia}/${mes}/${ano}`;
    },
    toggleCardsTable() {
      this.showCards = !this.showCards;
    },
    getUniqueDates(monto) {
      return [...new Set(monto.map((item) => item.fecha))];
    },
    getCount(monto, fecha) {
      return monto.filter((item) => item.fecha === fecha).length;
    },
  },
  computed: {
    groupedGastos() {
      if (!this.gastos) return { total: 0 };
      const groupedGastos = {};
      console.log("gastos", this.gastos);
      this.gastos.forEach((gasto) => {
        const fecha = gasto.fechaRegistro.split("T")[0];
        if (!groupedGastos[fecha]) {
          groupedGastos[fecha] = { total: 0 };
        }
        groupedGastos[fecha].total += Number(gasto.importe);
      });
      return groupedGastos;
    },
  },
  mounted() {
    // egresoService.getEgresos()
    //   .then(response => {
    //     this.gastos = response;
    //     this.gastosAgrupados = this.agruparGastosPorFecha(this.gastos);
    //     console.log(this.gastos);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  },
};
</script>
